import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql, Link} from "gatsby";
import PostList from "../components/postList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import * as styles from "./category.module.css";

export const pageQuery = graphql`
    query {
        allMicrocmsPost(
            sort: { fields: [publishedAt], order: DESC }
        ) {
            posts: edges {
                node {
                    postId
                    title
                    body
                    eyecatch {
                        url
                    }
                    category {
                        name
                        slug
                    }
                    tag {
                        name
                        slug
                    }
                    publishedAt(formatString: "YYYY年MM月DD日")
                    updatedAt
                }
            }
        }
    }
`

const TagPage = ({data, pageContext}) => {
    const {tag} = pageContext
    const posts = data.allMicrocmsPost.posts.filter((post) => {
        return post.node.tag.some((t) => t.slug === tag.slug)
    })
    return (
        <Layout>
            <Seo title={tag.name}/>
            <nav className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/"><FontAwesomeIcon icon={faHome} /></Link>
                    </li>
                    <li>
                        <Link to={`/tags/${tag.slug}`}>{tag.name}</Link>
                    </li>
                </ol>
            </nav>
            <h2>「{tag.name}」の記事</h2>
            <div className={styles.postList}>
                <PostList posts={posts.map(post => post.node)}/>
            </div>
        </Layout>
    )
}

export default TagPage